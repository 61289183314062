import { apiFormData } from 'src/api/axios';

export interface AcrylicPlateType {
  title: string;
  acrylic_plate_sizes: Array<{
    image: File;
    size_title: string;
    price: string;
    width: string;
    height: string;
    thickness: string;
    shipping_fee: string;
  }>;
}

export const useAddAcrylic = (params: AcrylicPlateType) => apiFormData.post(`/api/cms/acrylic-plate`, params);
