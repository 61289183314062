import { HEIGHT_STAGE, OUTLINE_PADDING_DEFAULT, RANGE_SLIDER_STEP_SIZE, WIDTH_STAGE } from 'src/assets/dataUI';
import styles from 'src/components/features/superAdmin/EditorComponent/FinalStep/index.module.scss';
import stylesImage from '../index.module.scss';
import { MenuStepEraseBrush } from '../../SvgMerge/menu';
import SvgMerge from '../../SvgMerge';
import MenuBottom from 'src/components/common/menuBottom';
import { Button, Slider } from 'antd';
import { useEffect, useState } from 'react';
import { generateSvgFromUrl } from '../../SvgMerge/ImageToSvg';
import { nodeToObject } from 'src/utils';
import { cloneDeep } from 'lodash';
import { DEFAULT_EXTRA_STATE, revertCalculationPointBeforeTransform } from '../../SvgMerge/utils';
import { endAddPathDelete, startAddPathDelete, startAddPathRestore } from '../../SvgMerge/controllers/TouchController';
import { useRatioContext } from 'src/hook/useChangeRatio';

const TAB_IMAGE = {
  eraser: 'eraser',
  recover: 'recover',
};

type Props = {
  imageSize: any;
  setImageSize: any;
  setImage: any;
  imageBackUp: any;
  setStep: (val: any) => void;
  svgContent: any;
  deletePath: any;
  setDeletePath: any;
  setSvgContent: any;
  newImage: any;
  setNewImage: any;
  isRemoveBg: any;
  setResetFromStep1: any;
  editMode: any;
  handleSetFeature: any;
};

export default function Step2({
  imageSize,
  setImageSize,
  setSvgContent,
  newImage,
  setNewImage,
  setImage,
  imageBackUp,
  setStep,
  svgContent,
  deletePath,
  setDeletePath,
  isRemoveBg,
  setResetFromStep1,
  editMode,
  handleSetFeature,
}: Props) {
  const [deletePathScale, setDeletePathScale] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRestore, setIsRestore] = useState(false);
  const [createNewImage, setCreateNewImage] = useState(false);
  const [tab, setTab] = useState('');
  const [cursorSize, setCursorSize] = useState(20);
  const {stateRatio} = useRatioContext()

  const [currentCursorPosition, setCurrentCursorPosition] = useState<any>({
    x: 0,
    y: 0,
  });

  const [pathChanged, setPathChanged] = useState(false);
  const [reUpdatePath, setReUpdatePath] = useState(false);

  useEffect(() => {
    if (createNewImage) {
      //get path from layer 3, get image from layer 2
      var baseSvg: any = document.getElementById('Layer_2');
      var svgString = new XMLSerializer().serializeToString(baseSvg);
      const svgLine = new Blob([svgString], {
        type: 'image/svg+xml;charset=utf-8',
      });
      var base64 = btoa(svgString);

      var imgSource = `data:image/svg+xml;charset=utf-8;base64,${base64}`;
      const urlBase = URL.createObjectURL(svgLine);

      var baseSvgPath: any = document.getElementById('Layer_3');
      var svgStringPath = new XMLSerializer().serializeToString(baseSvgPath);
      const svgLinePath = new Blob([svgStringPath], {
        type: 'image/svg+xml;charset=utf-8',
      });

      const urlBasePath = URL.createObjectURL(svgLinePath);
      var img = new Image();
      img.onload = function () {
        var canvas: any = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL();
        var imgPath = new Image();
        imgPath.onload = function () {
          var canvas2: any = document.createElement('canvas');
          var ctx2 = canvas2.getContext('2d');
          canvas2.height = imgPath.height;
          canvas2.width = imgPath.width;
          ctx2.drawImage(imgPath, 0, 0);
          let dataURL2 = canvas2.toDataURL();
          generateSvgFromUrl(dataURL2, (svg: any) => {
            if (svg) {
              const parser = new DOMParser();
              const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
              const svgContentData = nodeToObject(svgDoc);
              let maxTotal = 0;
              let minTotal = 999;
              svgContentData?.children[0]?.children.map(function (item: any, index: any) {
                let fillColorPath = item.attributes?.fill.replace('rgb(', '').replace(')', '').split(',');
                let totalColor = 0;
                if (fillColorPath.length == 3) {
                  totalColor = Number(fillColorPath[0]) + Number(fillColorPath[1]) + Number(fillColorPath[2]);
                }

                if (totalColor > maxTotal) {
                  maxTotal = totalColor;
                }

                if (totalColor < minTotal) {
                  minTotal = totalColor;
                }
                return true;
              });
              let filteredData = svgContentData?.children[0]?.children.filter(function (item: any, index: any) {
                let fillColorPath = item.attributes?.fill.replace('rgb(', '').replace(')', '').split(',');
                let totalColor = 0;
                if (fillColorPath.length == 3) {
                  totalColor = Number(fillColorPath[0]) + Number(fillColorPath[1]) + Number(fillColorPath[2]);
                }

                if (maxTotal - minTotal > 0 && minTotal != 254) {
                  if (totalColor >= maxTotal) {
                    //remove all thing that nearest to white color
                    return false;
                  } else {
                    return true;
                  }
                } else {
                  return true;
                }
              });

              setSvgContent({
                ...svgContent,
                ...svgContentData?.children[0],
                attributes: {
                  ...svgContentData?.children[0]?.attributes,
                  viewBox: `0 0 ${svgContentData?.children[0]?.attributes?.width} ${svgContentData?.children[0]?.attributes?.height}`,
                },
                matrix: false,
                children: deletePath.length == 0 ? svgContent?.children : filteredData,
                image_url: imgSource,
              });

              if (deletePath.length > 0) {
                setPathChanged(true);
              }

              setDeletePath([]);
              setDeletePathScale([]);
            }
          });
        };
        imgPath.src = urlBasePath;
      };

      img.src = urlBase;

      setCreateNewImage(false);
    }
  }, [createNewImage]);

  //this part using to recreate path again for better percise
  useEffect(() => {
    if (reUpdatePath) {
      if (pathChanged) {
        setReUpdatePath(false);
        //get path from layer 3, get image from layer 2
        var baseSvg: any = document.getElementById('Layer_2');
        var svgString = new XMLSerializer().serializeToString(baseSvg);
        const svgLine = new Blob([svgString], {
          type: 'image/svg+xml;charset=utf-8',
        });
        var base64 = btoa(svgString);

        var imgSource = `data:image/svg+xml;charset=utf-8;base64,${base64}`;
        const urlBase = URL.createObjectURL(svgLine);

        var baseSvgPath: any = document.getElementById('Layer_3');
        var svgStringPath = new XMLSerializer().serializeToString(baseSvgPath);
        const svgLinePath = new Blob([svgStringPath], {
          type: 'image/svg+xml;charset=utf-8',
        });

        const urlBasePath = URL.createObjectURL(svgLinePath);
        var img = new Image();
        img.onload = function () {
          var canvas: any = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          canvas.height = img.height;
          canvas.width = img.width;
          ctx.drawImage(img, 0, 0);
          let dataURL = canvas.toDataURL();
          var imgPath = new Image();
          imgPath.onload = function () {
            var canvas2: any = document.createElement('canvas');
            var ctx2 = canvas2.getContext('2d');
            canvas2.height = imgPath.height;
            canvas2.width = imgPath.width;
            ctx2.drawImage(imgPath, 0, 0);
            let dataURL2 = canvas2.toDataURL();
            generateSvgFromUrl(dataURL2, (svg: any) => {
              if (svg) {
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
                const svgContentData = nodeToObject(svgDoc);
                let maxTotal = 0;
                let minTotal = 999;
                svgContentData?.children[0]?.children.map(function (item: any, index: any) {
                  let fillColorPath = item.attributes?.fill.replace('rgb(', '').replace(')', '').split(',');
                  let totalColor = 0;
                  if (fillColorPath.length == 3) {
                    totalColor = Number(fillColorPath[0]) + Number(fillColorPath[1]) + Number(fillColorPath[2]);
                  }

                  if (totalColor > maxTotal) {
                    maxTotal = totalColor;
                  }

                  if (totalColor < minTotal) {
                    minTotal = totalColor;
                  }
                  return true;
                });
                let filteredData = svgContentData?.children[0]?.children.filter(function (item: any, index: any) {
                  let fillColorPath = item.attributes?.fill.replace('rgb(', '').replace(')', '').split(',');
                  let totalColor = 0;
                  if (fillColorPath.length == 3) {
                    totalColor = Number(fillColorPath[0]) + Number(fillColorPath[1]) + Number(fillColorPath[2]);
                  }

                  if (maxTotal - minTotal > 0) {
                    if (totalColor >= maxTotal) {
                      //remove all thing that nearest to white color
                      return false;
                    } else {
                      return true;
                    }
                  } else {
                    return true;
                  }
                });

                setSvgContent({
                  ...svgContent,
                  children: filteredData,
                  image_url: imgSource,
                });

                setPathChanged(false);
                setStep((prev: number) => prev + 1);
              }
            });
          };
          imgPath.src = urlBasePath;
        };

        img.src = urlBase;
      } else {
        setReUpdatePath(false);
        setStep((prev: number) => prev + 1);
      }
    }
  }, [reUpdatePath]);

  const onChangeDeletePath = ({ pathIndex, path, type, size }: any) => {
    let newData = cloneDeep(path);
    let newData2 = cloneDeep(path);
    let output: any = deletePath;
    let outputScale: any = deletePathScale;
    if (deletePath[pathIndex]) {
      output[pathIndex] = {
        path: newData.map((pathInfo: any) => {
          return revertCalculationPointBeforeTransform({
            transform: svgContent?.transform,
            rotate: svgContent?.rotate,
            scale: {
              base: svgContent?.scale?.base ?? 1,
            },
            point: pathInfo,
          });
        }),
        type: type,
        size: size,
      };

      outputScale[pathIndex] = {
        path: newData2.map((pathInfo: any) => {
          return revertCalculationPointBeforeTransform({
            transform: svgContent?.transform,
            rotate: svgContent?.rotate,
            scale: {
              base:
                ((svgContent?.scale?.base ?? 1) * svgContent?.width) /
                (imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width),
            },
            point: pathInfo,
          });
        }),
        type: type,
        size: (size * (imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width)) / svgContent?.width,
      };
    } else {
      output.push({
        path: newData.map((pathInfo: any) => {
          return revertCalculationPointBeforeTransform({
            transform: svgContent?.tranform,
            rotate: svgContent?.rotate,
            scale: svgContent?.scale,
            point: pathInfo,
          });
        }),
        type: type,
        size: size,
      });

      outputScale.push({
        path: newData2.map((pathInfo: any) => {
          return revertCalculationPointBeforeTransform({
            transform: svgContent?.tranform,
            rotate: svgContent?.rotate,
            scale: {
              base:
                ((svgContent?.scale?.base ?? 1) * svgContent?.width) /
                (imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width),
            },
            point: pathInfo,
          });
        }),
        type: type,
        size: (size * (imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width)) / svgContent?.width,
      });
    }

    setDeletePath(cloneDeep(output));
    setDeletePathScale(cloneDeep(outputScale));
  };

  return (
    <>
      {editMode && <div className="flex justify-center mb-3 text-[24px] text-[#515c6f]">画像調整</div>}

      <div className={styles.editor}>
        <div
          className={`${styles.stage} ${styles.stageImage}`}
          style={
            {
              width: stateRatio?.ratioWidth,
              height: stateRatio?.ratioHeight,
              '--bg-pos':
                isDeleting || isRestore
                  ? `${currentCursorPosition?.x - (cursorSize * (editMode ? svgContent?.scale?.base : 1)) / 2}px ${
                      currentCursorPosition?.y - (cursorSize * (editMode ? svgContent?.scale?.base : 1)) / 2
                    }px`
                  : `0px 0px`,
              '--bg-size': `${cursorSize * (editMode ? svgContent?.scale?.base : 1)}px ${
                cursorSize * (editMode ? svgContent?.scale?.base : 1)
              }px`,
            } as React.CSSProperties
          }
        >
          {svgContent && (
            <SvgMerge
              shapeData={[
                {
                  type: 9,
                  ...svgContent,
                  imageBackUp: imageBackUp,
                  deletePath,
                },
              ]}
              outline={{
                showOutline: false,
              }}
              extraState={DEFAULT_EXTRA_STATE}
              isDeleteMode={isDeleting}
              isRestoreMode={isRestore}
              onDragDeleteEnd={onChangeDeletePath}
              onChangeCursorPosition={setCurrentCursorPosition}
              cursorSize={cursorSize}
            />
          )}
        </div>
      </div>

      <div style={{ display: 'none' }}>
        <SvgMerge
          shapeData={[
            {
              type: 9,
              ...svgContent,
              outlinePadding: OUTLINE_PADDING_DEFAULT,
              imageBackUp: imageBackUp,
              scale: {
                base: 2,
              },
              width: imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width,
              height: imageSize?.baseHeight ?? svgContent?.lastShapeSizeData?.height,
              attributes: {
                ...svgContent.attributes,
                height: imageSize?.baseHeight ?? svgContent?.lastShapeSizeData?.height,
                width: imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width,
                viewBox: `0 0 ${imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width} ${
                  imageSize?.baseHeight ?? svgContent?.lastShapeSizeData?.height
                }`,
              },
              deletePath: deletePathScale,
            },
          ]}
          outline={{
            showOutline: false,
          }}
          extraState={DEFAULT_EXTRA_STATE}
          isDeleteMode={isDeleting}
          isRestoreMode={isRestore}
          onDragDeleteEnd={onChangeDeletePath}
          isSingleShowMode={true}
          whiteBackground={false}
        />
        <SvgMerge
          shapeData={[
            {
              type: 9,
              ...svgContent,
              outlinePadding: OUTLINE_PADDING_DEFAULT,
              imageBackUp: imageBackUp,
              scale: {
                base: 2,
              },
              deletePath,
            },
          ]}
          outline={{
            showOutline: false,
          }}
          extraState={DEFAULT_EXTRA_STATE}
          isDeleteMode={isDeleting}
          isRestoreMode={isRestore}
          onDragDeleteEnd={onChangeDeletePath}
          isSingleShowMode={true}
          whiteBackground={true}
        />
      </div>

      <canvas id="canvas" style={{ display: 'none' }}></canvas>

      <div className="my-react-add-text flex justify-center mb-2">
        <div className="w-[500px]">
          {tab && (
            <div className={`${stylesImage.content} ${stylesImage.contentStep2}`}>
              {tab === TAB_IMAGE?.eraser ? (
                <>
                  <p className={styles.scaleTitle}>消しゴム</p>

                  <div className={stylesImage.scale}>
                    <Slider
                      tooltip={{ formatter: null }}
                      defaultValue={cursorSize}
                      min={10}
                      max={30}
                      step={RANGE_SLIDER_STEP_SIZE}
                      onChange={(e) => {
                        setCursorSize(e);
                      }}
                    />
                  </div>

                  <div className="flex justify-center w-full mt-1 ">
                    <Button
                      className="button-form button-form-Submit !w-auto"
                      onClick={(e: any) => {
                        setIsDeleting(false);
                        endAddPathDelete(1);
                        setCreateNewImage(true);
                        setTab('');
                      }}
                    >
                      編集した内容反映
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <p className={styles.scaleTitle}>修復ブラシ</p>

                  <div className={stylesImage.scale}>
                    <Slider
                      tooltip={{ formatter: null }}
                      defaultValue={cursorSize}
                      min={10}
                      max={30}
                      step={RANGE_SLIDER_STEP_SIZE}
                      onChange={(e) => {
                        setCursorSize(e);
                      }}
                    />
                  </div>

                  <div className="flex justify-center w-full mt-1 ">
                    <Button
                      className="button-form button-form-Submit !w-auto"
                      onClick={(e: any) => {
                        setIsRestore(false);
                        endAddPathDelete(1);
                        setCreateNewImage(true);
                        setTab('');
                      }}
                    >
                      編集した内容反映
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {!tab && (
        <MenuBottom
          list={MenuStepEraseBrush({
            onClickErase: (e: any) => {
              setTab(TAB_IMAGE.eraser);
              if (isDeleting) {
                // setIsDeleting(false);
                // endAddPathDelete(1);
                // setCreateNewImage(true);
              } else {
                setIsDeleting(true);
                startAddPathDelete(deletePath.length);
              }
            },
            onClickBrush: (e: any) => {
              setTab(TAB_IMAGE.recover);
              if (isRestore) {
                // setIsRestore(false);
                // endAddPathDelete(1);
                // setCreateNewImage(true);
              } else {
                setIsRestore(true);
                startAddPathRestore(deletePath.length);
              }
            },
          })}
        />
      )}

      <div className="flex justify-center mt-3 gap-2">
        <Button
          disabled={!!tab}
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            if (editMode) {
              handleSetFeature();
            } else {
              setStep((prev: number) => prev - 1);
              setResetFromStep1((prev: any) => !prev);
            }
          }}
        >
          {'<<前のステップへ'}
        </Button>
        <Button
          disabled={!!tab}
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            setStep((prev: number) => prev + 1);
          }}
        >
          {'次のステップへ>>'}
        </Button>
      </div>
    </>
  );
}
