import React from "react";

//{
// type: 6,//path
// d: "M150 5 L75 200 L225 200 Z",
// fillRule: "evenodd",
// clipRule: "evenodd"
// fillColor: 'red'
// }
const PathSvg = ({
	shapeInfo,
	outline,
	drawOutline,
	handleClick,
	index,
}: any) => {
	if (drawOutline) {
		return (
			<path
				id={index}
				onClick={handleClick}
				d={shapeInfo.d}
				fill={outline?.paddingColor ?? "white"}
				fillRule={shapeInfo?.fillRule}
				clipRule={shapeInfo?.clipRule}
				stroke={shapeInfo?.stroke ?? "black"}
				strokeWidth={shapeInfo?.outlinePadding ?? "0.5"}
				strokeLinecap="round"
				strokeLinejoin="round"
				transform={
					(shapeInfo?.transform
						? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})`
						: "") +
					(shapeInfo?.matrix
						? ` matrix(-1, 0, 0, 1, ${shapeInfo?.width / 2}, 0)`
						: "")
				}
			/>
		);
	} else {
		if (shapeInfo?.href) {
			return (
				<image
					href={shapeInfo?.href}
					width={shapeInfo?.width}
					height={shapeInfo?.height}
					onClick={handleClick}
					strokeWidth={shapeInfo?.outlinePadding ?? "0.5"}
					strokeLinecap="round"
					strokeLinejoin="round"
					transform={
						(shapeInfo?.transform
							? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})`
							: "") +
						(shapeInfo?.matrix
							? ` matrix(-1, 0, 0, 1, ${shapeInfo?.width / 2}, 0)`
							: "")
					}
				/>
			);
		} else {
			return (
				<path
					d={shapeInfo.d}
					fill={shapeInfo?.fillColor}
					fillRule={shapeInfo?.fillRule}
					clipRule={shapeInfo?.clipRule}
					onClick={handleClick}
					transform={
						shapeInfo?.transform
							? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})`
							: "" +
								(shapeInfo?.matrix
									? ` matrix(-1, 0, 0, 1, ${shapeInfo?.width / 2}, 0)`
									: "")
					}
				/>
			);
		}
	}
};

export default PathSvg;
