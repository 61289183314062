/* eslint-disable react/jsx-key */
import React from 'react';
import GenerateSmallMaskSvg from '../element/GenerateSmallMaskSvg';

const SmallMaskPath = ({ shapeData, hiddenIndex }: any) => {
  return (
    <mask id="smallmask">
      <circle cx="200" cy="200" r="2000" fill="white" />
      {shapeData?.map((shapeInfo: any, index: any) => {
        if (hiddenIndex === index || ![98, 99, 100].includes(shapeInfo?.type)) {
          <></>;
        } else {
          return <GenerateSmallMaskSvg shapeInfo={shapeInfo} />;
        }
      })}
    </mask>
  );
};

export default SmallMaskPath;
